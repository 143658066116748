* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  --theme: #000;
  --dots: #000;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  justify-content: end;
  color: var(--theme);
  
  &.orange {
    --theme: #fff;
    --dots: #fff;
  }
  
  &.black {
    --theme: #EF5B2A;
    --dots: #EF5B2A;
  }

  &.hidden {
    opacity: 0;
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end ;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  .black & { display: none; }
}

footer {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 1rem;
  font-weight: bold;
  border: 2px solid var(--theme);
  padding: 0.5rem 1rem;

  a {
    color: var(--theme);
    text-decoration: none;
  }
}

small {
  --size: 0.4rem;
  --space: -1.1rem;

  position: relative;
  display: block;
  font-family: Arial, Helvetica, sans-serif;

  // &:before,
  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   width: var(--size);
  //   height: var(--size);
  //   background-color: var(--dots);
  //   border-radius: 100%;
  //   transform: translateY(-50%);
  // }

  // &:before {
  //   left: var(--space);
  // }

  // &:after {
  //   right: var(--space);
  // }
}